import React, {useEffect} from 'react';
import {Modal, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import * as Theme from '../Theme';

export function usePromoAppModal() {
  const [open, setOpen] = useOutlet('promo-app-modal');

  React.useEffect(() => {
    if (
      typeof navigator !== 'undefined' &&
      [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ].some((re) => {
        return navigator.userAgent.match(re);
      })
    ) {
      setOpen(true);
    }
  }, []);

  return null;
}

export default function PromoAppModal() {
  const [open, setOpen] = useOutlet('promo-app-modal');

  return (
    <Modal
      visible={open}
      onCancel={() => setOpen(false)}
      bodyStyle={{padding: 20, borderRadius: 8}}
      style={{padding: 0, borderRadius: 8}}
      closable={false}
      centered
      footer={null}
      destroyOnClose>
      <div style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
        <img src="images/icon-close.png" style={{width: 20, height: 20}} />
      </div>
      <div style={{fontSize: 18, textAlign: 'center'}}>
        <img
          src="images/promo-modal-image.jpg"
          style={{width: '100%', marginTop: 20}}
        />

        {/* <div style={{marginTop: 20}}>現在立即下載</div>
        <div>
          登入 <span style={{color: Theme.colors.main}}>LAYA NOW App</span> 會員
        </div>
        <div>更多驚喜優惠活動，等你來發掘！ </div> */}

        <div style={{marginTop: 20}}>線上支付滿200元+任一天空抹茶聯名餐點</div>
        <div>馬上有機會抽中日本靜岡之旅</div>
        <div>
          限 <span style={{color: Theme.colors.main}}>LayaNOW App</span> 會員
        </div>
        <div>網頁版點餐恕不適用</div>

        <Button
          type="primary"
          size="large"
          style={{width: '100%', marginTop: 40}}
          onClick={() => window.open('https://onelink.to/ugec88')}>
          立即下載 LAYA NOW
        </Button>
        <Button
          size="large"
          style={{width: '100%', marginTop: 10}}
          onClick={() => setOpen(false)}>
          繼續網頁版點餐
        </Button>
      </div>
    </Modal>
  );
}
